/* Header.css */
.headerContainer {
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  /* background-color: #00000015; */
  /* Background gradient, dark at the top and transparent at the bottom */
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.86) 30%, rgba(0, 0, 0, 0) 100%);
  color: #ffffff;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  width: 115px;
}

.logo img {
  width: 150px;
  max-width: none;
}

.nav {
  display: flex;
  gap: 3rem;
  font-weight: bold;
  padding: 1rem;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.nav a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav--open {
  display: block;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #fff;
  color: #000
}

.menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: white;
  font-family: 'Bebas Neue', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
  
  .nav--open {
    display: block;
  }

  .menu-button {
    display: block;
  }
}
