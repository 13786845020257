@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* Inter with all weights */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  font-family: 'Bebas Neue', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-item {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  font-size: 1.8rem;
  letter-spacing: 1px;
}

.pageWrapper {
  overflow: hidden;
}

.splashContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  color: white;
  text-align: center;
  z-index: 1;
  overflow-x: hidden;
}

.splash {
  max-width: 768px;
}

.text {
  cursor: pointer;
  user-select: none;
}

.shipWheel {
  width: 77px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .shipWheel {
    transform: translate(0px, 0px);
    width: 50px;
  }
}

.jumboHeaderText {
  font-size: 9rem;
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  line-height: 0.9;
  letter-spacing: 1px;
  font-weight: 300;
}

.jumboHeaderTextSm {
  font-size: 6rem;
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  line-height: 0.9;
  letter-spacing: 1px;
  font-weight: 300;
}

.fadeInText {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.titleText {
  font-size: 5rem;
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  line-height: 0.9;
  letter-spacing: 1px;
  font-weight: 300;
  opacity: 1;
}

.headerAdjusted {
  position: absolute;
  margin: 0 auto;
  margin-top: 180px;
  width: 100%;
  z-index: 9999;
}

.titleText.subTitle {
  font-size: 3.5rem;
  color: #d6d6d6;
}

.titleText {
  text-shadow: 3px 4px 10px black;
}

.recipeListingItem {
  margin-bottom: 24px;
}

@media (max-width: 1400px) {
  .jumboHeaderText {
    font-size: 5rem;
  }
}

@media (max-width: 768px) {
  .jumboHeaderText {
    font-size: 4rem;
  }
  .menu-item {
    font-size: 1.2rem;
  }
  .headerAdjusted {
    margin-top: 120px;
}
.titleText.subTitle {
  font-size: 2.25rem;
}
.downwardArrow {
  top: 80% !important;
}
}

.can-image {
  transform: translateX(-50%);
  width: 50px; /* Adjust as needed */
  height: auto; /* This will maintain aspect ratio */
  opacity: 0; /* Start invisible */
  position: absolute;
  bottom: 50%;
  width: 72px;
}

@keyframes zoomFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

section {
  background: white;
}

.videoOverlayContainer {
  height: 100vh;
  position: absolute;
  width: 100%;
}

.downwardArrow {
  position: absolute;
    top: 88%;
    color: white;
    z-index: 9999;
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    opacity: 1;
    width: 100%;
}

.downwardArrow > p {
  padding: 0px 18px 0px 18px;
  display: inline-block;
  margin: 0;
  cursor:pointer;
}

.contentContainer {
  z-index: 10000;
  position: relative;
  font-size: 20px;
  letter-spacing: 0.75px;
}

/* make downward arrow bounce, slowly up and down, linear */

@keyframes bounce {
  0%, 100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

.fadeDownToBlack {
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.86) 30%, rgba(0, 0, 0, 0) 100%);
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 9999;
}

.bouncing {
  animation: bounce 3s infinite, fadeIn 5s;
}

.contentContainer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
  .container {
    max-width: 100%; /* Allows the container to fill the screen */
    padding: 0 28px;
    padding-bottom: 64px;
  }
}

.zoom-fade-effect {
  animation: zoomFade 2s forwards cubic-bezier(0.9, 0.0, 0.05, 1);
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

/* touch-action: pan-x; */
.touch-action {
  touch-action: pan-x;
}

.calmex-gradient {
  /* Why is wild-caught abalone superior? */

background: linear-gradient(92.55deg, #FF6C9C 47.24%, #FFB3CB 70.37%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.inline-menu-items a {
  position: relative;
  bottom: 37px;
}

.background-wheel {
  /* On the right side of the page, place background-wheel.png background image */
  background-image: url('/public/background-wheel.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 35%;
}

.background-wheel-sm {
  /* On the right side of the page, place background-wheel.png background image */
  background-image: url('/public/background-wheel.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 25%;
}

/* On mobile, make it larger */
@media (max-width: 1024px) {
  .background-wheel, .background-wheel-sm {
    background-size: 100%;
    background-position: right;
  }
}

#PlayBackRatePanelYPSC {
  display: none !important;
}

.post {
  margin: 0 auto;
}

.post-thumbnail:first-of-type {
  margin-top: 0;
}

.post-thumbnail, .wp-block-image {
  max-width: 668px;
  margin: 0 auto;
  margin-top: 36px;
  margin-bottom: 36px;
}

.post-thumbnail > img, .post img {
  border-radius: 10px;
  border: 1px solid pink;
}

.post p, .post > p {
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 668px;
}
